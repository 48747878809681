import * as React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const Previewer = ({ fileUrl, extension, type }) => {
  console.log(fileUrl, extension, type , 'jjjjjjkkkkkkkk')
  const docs = [
    {
      uri: fileUrl,
      fileType: extension,
      fileName: 'Document Preview',
    }
  ];

  if (type.startsWith('audio')) {
    return <MusicPreview src={fileUrl} fileExtension={extension} />;
  } else if (type.startsWith('video')) {
    return <VideoPreview src={fileUrl} fileExtension={extension} />;
  } else {
    return (
      <div>
        <DocViewer documents={docs} pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: false,
            },
            csvDelimiter: ",", // "," as default,
            pdfZoom: {
              defaultZoom: 1.1, // 1 as default,
              zoomJump: 0.2, // 0.1 as default,
            },
            pdfVerticalScrollByDefault: true, // false as default
          }}
          style={{ height: 550 }} />
      </div>
    );
  }

};

export default Previewer;

const MusicPreview = ({ src, fileExtension }) => {
  return (
    <div className="d-flex justify-content-center my-4">
      <audio controls>
        <source src={src} type={`audio/${fileExtension}`}  />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

const VideoPreview = ({ src, fileExtension }) => {
  return (
    <div className="d-flex justify-content-center my-4">
        <video controls>
          <source src={src} type={`video/${fileExtension}`} />
        </video>
    </div>
  );
};
